import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import {
  Loader,
  Menu,
} from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import './App.css';
import logo from './logo.png';

import { Api, Domain } from './service';
import Login from './Login';
import Register from './Register';
import RegisterVerification from './RegisterVerification';
import ResetPassword from './ResetPassword';
import ResetPasswordVerification from './ResetPasswordVerification';
import ResetPasswordForm from './ResetPasswordForm';
import ResetPasswordSuccess from './ResetPasswordSuccess';

function App() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    Api.get('sanctum/csrf-cookie').then(() => setLoading(false)).catch(() => setLoading(false))
  })

  return (
    <Router>
      <div className="App">
        <Menu borderless={true} secondary style={{marginBottom: 0}}>
          <Menu.Item name='logo'>
            <a href={Domain.wwwUrl} target="_blank" rel="noreferrer" style={{lineHeight: 0}}>
              <img src={logo} style={{width: 150, margin: 8}} alt="zirconnect icon" />
            </a>
          </Menu.Item>
        </Menu>
        <main>
          {loading ? <Loader /> :
          <Switch>
            <Route path='/' exact>
              <Redirect to={Login.path} />
            </Route>

            <Route path={Login.path}>  
              <Login.Page />
            </Route>

            <Route path={Register.path}>
              <Register.Page />
            </Route>

            <Route path={RegisterVerification.path}>
              <RegisterVerification.Page />
            </Route>

            <Route path={ResetPassword.path} exact>
              <ResetPassword.Page />
            </Route>

            <Route path={ResetPasswordVerification.path}>
              <ResetPasswordVerification.Page />
            </Route>

            <Route path={ResetPasswordForm.path}>
              <ResetPasswordForm.Page />
            </Route>

            <Route path={ResetPasswordSuccess.path}>
              <ResetPasswordSuccess.Page />
            </Route>
          </Switch>
        }
        </main>
        <footer>
          © 2021 Zirconnect.com. All rights reserved
        </footer>
      </div>
    </Router>
  );
}

export default App;
