import { useEffect, useState }  from 'react';
import { Link } from "react-router-dom";
import {
  Button,
  Container,
  Grid,
  Header,
  Icon,
  Input,
  Form,
  Modal,
  Segment,
} from 'semantic-ui-react';
import { AxiosResponse, AxiosError } from 'axios';
import { useCookies } from 'react-cookie';
import { Helmet } from 'react-helmet';
import ReCAPTCHA from 'react-google-recaptcha';
import { Api, SiteKey, CookieToken, Domain, isProduction, DeviceData } from './service';

const path = '/login';

function Page() {
  const [, setCookie] = useCookies([CookieToken.name]);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordHide, setPasswordHide] = useState(true);
  const [errorVisible, setErrorVisible] = useState(false);
  const [emailError, setEmailError] = useState<string>();
  const [passwordError, setPasswordError] = useState<string>();
  const [open, setOpen] = useState(false);
  const [openLoginFailModel, setOpenLoginFailModel] = useState(false);
  const [openCaptcha, setOpenCaptcha] = useState(false);
  const [captchaToken, setCaptchaToken] = useState<string>();

  useEffect(() => {
    if (email !== undefined) {
      if (email.length === 0) {
        setEmailError('Please enter your email');
      } else if (!/\S+@\S+\.\S+/.test(email)) {
        setEmailError('Please enter a correct email address');
      } else {
        setEmailError(undefined);
      }
    } 

    if (password !== undefined) {
      if (password.length === 0) {
        setPasswordError('Please enter your password');
      } else {
        setPasswordError(undefined);
      }
    }
  }, [email, password]);

  const handleSubmit = () => {
    setErrorVisible(true);

    if (emailError || passwordError) {
      return;
    }

    setOpenCaptcha(true);
  }

  const login = (captchaToken: string) => {
    setLoading(true);

    Api.post('api/account/login', {
      email: email,
      password: password,
      device_name: DeviceData,
      captcha_token: captchaToken,
    }).then((value: AxiosResponse) => {
      setCookie(CookieToken.name, value.data.access_token, {
        domain: CookieToken.domain,
        path: '/',
        secure: isProduction,
        expires: CookieToken.expires(),
        sameSite: 'lax',
        httpOnly: false
      });
      window.location.assign(Domain.wwwUrl);
    })
    .catch((reason: AxiosError) => {
      setLoading(false);

      if (reason.response?.status === 401 || reason.response?.status === 404) {
        setOpenLoginFailModel(true);
      } else {
        setOpen(true);
      }
    });
  }

  return (
    <>
      <Helmet>
        <title>Log In | Zirconnect</title>
        <link rel="canonical" href={Api.defaults.baseURL + path} />
      </Helmet>

      <div className="notice">
        <div className="notice-icon">
          <Icon name='lock' size='small' />
        </div>
        <span style={{fontSize:12}}>
          <span style={{fontWeight: 700, marginRight: 4}}>URL verification:</span>
          <span style={{fontWeight: 400}}>
            <span style={{color: "rgb(14, 203, 129)"}}>https://</span>accounts.zirconnect.com</span>
        </span>
      </div>

      <Container text style={{marginTop:64}}>
        <Header as='h1'>Zirconnect Account Login</Header>
        <p className="description">Welcome back! Log In with your Email or QR code</p>

        <Grid>
          <Grid.Column mobile={16} tablet={9} computer={9}>
            <Form size='big' onSubmit={handleSubmit}>
              <Form.Field error={errorVisible && emailError !== undefined}>
                <label style={{fontSize: 14, fontWeight: 400}}>Email</label>
                <Input
                  value={email}
                  onChange={(_, data) => setEmail(data.value)}
                  disabled={loading}
                />
                {errorVisible && <div style={{fontSize: 14, color: 'rgb(207, 48, 74)'}}>{emailError}</div>}
              </Form.Field>
              <Form.Field error={errorVisible && passwordError !== undefined}>
                <label style={{fontSize: 14, fontWeight: 400}}>Password</label>
                <Input
                  type={passwordHide ? 'password' : 'text'}
                  value={password}
                  onChange={(_, data) => setPassword(data.value)}
                  disabled={loading}
                  icon={<Icon name={passwordHide ? 'eye slash' : 'eye'} link onClick={() => setPasswordHide(!passwordHide)}/>}
                />
                {errorVisible && <div style={{fontSize: 14, color: 'rgb(207, 48, 74)'}}>{passwordError}</div>}
              </Form.Field>
              <Button type='submit' size='big' color='blue' fluid loading={loading} disabled={loading}>Log In</Button>
            </Form>

            <div style={{marginTop: 24, fontSize: 14}}>
              <p>
                <Link to="/reset-password">Forgot password?</Link>
              </p>
              <p>
                <Link to="/register">Register now</Link>
              </p>
            </div>
          </Grid.Column>
          <Grid.Column tablet={7} computer={7} only='tablet computer' style={{display: 'none'}}>
            <div style={{textAlign: 'center', float: 'right', maxWidth: 250}}>
              <Segment loading={true} style={{height: 180, width: 180, margin: 'auto'}}>

              </Segment>
              <p style={{fontWeight: 700, marginTop: 24}}>Log in with QR code</p>
              <p style={{fontSize: 14}}>
                Scan this code with the&nbsp;
                <a href={Domain.wwwUrl + '/download'} target="_blank" rel="noreferrer">Zirconnect mobile app</a>
                &nbsp;to log in instantly.
              </p>
            </div>
          </Grid.Column>
        </Grid>

        <Modal size='mini' open={openLoginFailModel}>
          <Modal.Content>
            <p>Incorrect email or password</p>
          </Modal.Content>
          <Modal.Actions>
            <Button color='blue' onClick={() => setOpenLoginFailModel(false)} fluid style={{marginLeft: 0}}>
              I understand
            </Button>
          </Modal.Actions>
        </Modal>

        <Modal size='mini' open={open}>
          <Modal.Content>
            <p>Service Unavailable</p>
          </Modal.Content>
          <Modal.Actions>
            <Button color='blue' onClick={() => setOpen(false)} fluid style={{marginLeft: 0}}>
              OK
            </Button>
          </Modal.Actions>
        </Modal>

        <Modal style={{minWidth: 345}} size='mini' open={openCaptcha} onClose={() => !captchaToken && setOpenCaptcha(false)}>
          <Modal.Header>
            Security Verification
          </Modal.Header>
          <Modal.Content>
            <div style={{width: 304, height: 78, margin: '0 auto'}}>
              <ReCAPTCHA
                sitekey={SiteKey}
                onChange={(token) => {
                  setCaptchaToken(token ?? undefined);
                  if (token) {
                    setTimeout(() => {
                      setOpenCaptcha(false);
                      login(token);
                      setCaptchaToken(undefined);
                    }, 1500);
                  }
                }}
              />
            </div>
          </Modal.Content>
        </Modal>
      </Container>
    </>
  )
}

const Login = { path, Page }

export default Login;
