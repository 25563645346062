import { useEffect, useState }  from 'react';
import { useHistory } from "react-router-dom";
import {
  Button,
  Container,
  Grid,
  Header,
  Icon,
  Input,
  Form,
  Modal,
} from 'semantic-ui-react';
import { Helmet } from 'react-helmet';
import { Api, SessionStorageKey } from './service';
import ResetPasswordVerification from './ResetPasswordVerification';
import ResetPasswordSuccess from './ResetPasswordSuccess';

const path = '/reset-password/3';

function Page() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [pConfirm, setPConfirm] = useState('');
  const [errorVisible, setErrorVisible] = useState(false);
  const [passwordError, setPasswordError] = useState<string>();
  const [pConfirmError, setPConfirmError] = useState<string>();
  const [passwordHide, setPasswordHide] = useState(true);
  const [pConfirmHide, setPConfirmHide] = useState(true);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const text = sessionStorage.getItem(SessionStorageKey.resetPasswordToken)

    if (text === null) {
      history.replace(ResetPasswordVerification.path);
    } else {
      const storage = JSON.parse(text);
      setToken(storage.token);
      setEmail(storage.email);
    }
  }, [history]);

  useEffect(() => {
    if (password !== undefined) {
      if (password.length === 0) {
        setPasswordError('Please enter your new password');
      } else if (password.length < 8) {
        setPasswordError('Password must be at least 8 characters');
      } else {
        setPasswordError(undefined);
      }
    }

    if (pConfirm !== undefined) {
      if (pConfirm.length === 0) {
        setPConfirmError('Please enter your confirm password');
      } else if (password !== pConfirm) {
        setPConfirmError('New password and confirm password must match');
      } else {
        setPConfirmError(undefined);
      }
    }
  }, [password, pConfirm]);

  const handleSubmit = () => {
    setErrorVisible(true);

    if (passwordError || pConfirmError) {
      return;
    }

    setLoading(true);

    Api.post('api/account/reset-password', {
      token: token,
      email: email,
      password: password,
      password_confirmation: pConfirm,
    }).then(() => {
      sessionStorage.removeItem(SessionStorageKey.resetPasswordToken);
      history.push(ResetPasswordSuccess.path);
    })
    .catch(() => {
      setOpen(true);
      setLoading(false);
    });
  }

  return (
    <>
      <Helmet defer={false}>
        <title>Reset Your Password | Zirconnect</title>
        <link rel="canonical" href={Api.defaults.baseURL + path} />
      </Helmet>
      <Container text style={{marginTop:64}}>
        <Header as='h1'>Reset Your Password</Header>
        <p className="description"></p>

        <Grid>
          <Grid.Column mobile={16} tablet={9} computer={9}>
            <Form size='big' onSubmit={handleSubmit}>
              <Form.Field error={errorVisible && passwordError !== undefined}>
                <label style={{fontSize: 14, fontWeight: 400}}>New Password</label>
                <Input
                  type={passwordHide ? 'password' : 'text'}
                  value={password}
                  onChange={(_, data) => setPassword(data.value)}
                  icon={<Icon name={passwordHide ? 'eye slash' : 'eye'} link onClick={() => setPasswordHide(!passwordHide)}/>}
                  disabled={loading}
                />
                {errorVisible && <span style={{fontSize: 14, color: 'rgb(207, 48, 74)'}}>{passwordError}</span>}
              </Form.Field>
              <Form.Field error={errorVisible && pConfirmError !== undefined}>
                <label style={{fontSize: 14, fontWeight: 400}}>Confirm Password</label>
                <Input
                  type={pConfirmHide ? 'password' : 'text'}
                  value={pConfirm}
                  onChange={(_, data) => setPConfirm(data.value)}
                  icon={<Icon name={pConfirmHide ? 'eye slash' : 'eye'} link onClick={() => setPConfirmHide(!pConfirmHide)}/>}
                  disabled={loading}
                />
                {errorVisible && <span style={{fontSize: 14, color: 'rgb(207, 48, 74)'}}>{pConfirmError}</span>}
              </Form.Field>
              <Button type='submit' size='big' color='blue' fluid loading={loading} disabled={loading}>Next</Button>
            </Form>
          </Grid.Column>
        </Grid>

        <Modal size='mini' open={open}>
          <Modal.Content>
            <p>Service Unavailable</p>
          </Modal.Content>
          <Modal.Actions>
            <Button color='blue' onClick={() => setOpen(false)} fluid style={{marginLeft: 0}}>
              OK
            </Button>
          </Modal.Actions>
        </Modal>
      </Container>
    </>
  );
}

const ResetPasswordForm = { path, Page }

export default ResetPasswordForm;
