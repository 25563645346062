import { useHistory } from "react-router-dom";
import {
  Button,
  Container,
  Grid,
  Header,
  Icon,
} from 'semantic-ui-react';
import { Helmet } from 'react-helmet';
import { Api } from './service';
import Login from "./Login";

const path = '/reset-password/4';

function Page() {
  const history = useHistory();

  return (
    <>
      <Helmet defer={false}>
        <title>Reset Your Password | Zirconnect</title>
        <link rel="canonical" href={Api.defaults.baseURL + path} />
      </Helmet>
      <Container text style={{marginTop:150}}>
        <Grid centered>
          <Grid.Column mobile={16} tablet={9} computer={9} style={{textAlign: 'center'}}>
            <Icon name='check circle outline' size='huge' color='green'/>
            <Header as='h3'>Password Changed</Header>
            <p className="description">Please log in with the new password.</p>
            <Button color='blue' size='big' fluid onClick={() => history.push(Login.path)}>Log In</Button>
          </Grid.Column>
        </Grid>
      </Container>
    </>
  );
}

const ResetPasswordSuccess = { path, Page }

export default ResetPasswordSuccess;
