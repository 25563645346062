import axios from 'axios';
import { deviceDetect } from 'react-device-detect';

export const isProduction = 'production' === process.env.NODE_ENV

export const Domain = isProduction ? {
  wwwUrl: 'https://www.zirconnect.com',
  apiUrl: 'https://api.zirconnect.com',
} : {
  wwwUrl: 'http://www.zirconnect.test:3000',
  apiUrl: 'http://api.zirconnect.test:8000',
}

export const Api = axios.create({
  baseURL: Domain.apiUrl,
  withCredentials: true,
});

export const SessionStorageKey = {
  register: '_register',
  resetPassword: '_reset-password',
  resetPasswordToken: '_reset-password-token',
}

export const SiteKey = isProduction ?
  '6Lf8slQcAAAAACZP24wFQ2aPqD4OeSPgsD3Ilvop' :
  '6Lc4JE0cAAAAAEBvpCeggRnU7RGVHjM5z8SIZ45B';

const getCookieExpires = () => {
  let date = new Date();
  return new Date(date.setMonth(date.getMonth() + 1));
}

export const CookieToken = {
  name: '__ZIRCONNECT_USER',
  domain: isProduction ? '.zirconnect.com' : '.zirconnect.test',
  expires: getCookieExpires,
}

const deviceData = deviceDetect();

export const DeviceData = `${deviceData.browserName ?? 'Unknown Browser'} ${deviceData.browserMajorVersion ?? ''}, ${deviceData.osName ?? 'Unknown OS'}`;
