import React, { useEffect, useState }  from 'react';
import { useHistory } from "react-router-dom";
import {
  Button,
  Container,
  Grid,
  Header,
  Input,
  Form,
  Modal,
} from 'semantic-ui-react';
import { AxiosResponse, AxiosError } from 'axios';
import { useCookies } from 'react-cookie';
import { Helmet } from 'react-helmet';
import { Api, SessionStorageKey, DeviceData, CookieToken, isProduction, Domain } from './service';

const path = '/verification-new-register';

function Page() {
  const history = useHistory();
  const [, setCookie] = useCookies([CookieToken.name]);
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [uniqueId, setUniqueId] = useState('');
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState('');
  const [openHint, setOpenHint] = useState(false);

  useEffect(() => {
    const text = sessionStorage.getItem(SessionStorageKey.register)

    if (text === null) {
      history.replace('/register');
    } else {
      const storage = JSON.parse(text);
      setEmail(storage.email);
      setPassword(storage.password);
      setUniqueId(storage.uniqueId);
    }
  }, [history]);

  useEffect(() => {
    if (code.length === 6) {
      setLoading(true);
 
      Api.post('api/account/register', {
        name: email.substring(0, email.indexOf('@')),
        email: email,
        password: password,
        password_confirmation: password,
        device_name: DeviceData,
        unique_id: uniqueId,
        otp: code,
      }).then((value: AxiosResponse) => {
        sessionStorage.removeItem(SessionStorageKey.register);
        setCookie(CookieToken.name, value.data.access_token, {
          domain: CookieToken.domain,
          path: '/',
          secure: isProduction,
          expires: CookieToken.expires(),
          sameSite: 'lax',
          httpOnly: false
        });
        window.location.assign(Domain.wwwUrl);
      })
      .catch((reason: AxiosError) => {
        if (reason.response?.status === 400) {
          setContent('Invalid verification code');
        } else {
          setContent('Service Unavailable');
        }
  
        setOpen(true);
        setLoading(false);
        setCode('');
      });
    }
  }, [code, email, password, uniqueId, setCookie]);

  const resendEmail = () => {
    Api.post('api/account/register/resend', {
      unique_id: uniqueId,
    })
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (new RegExp('^\\d*$').test(event.target.value)) {
      setCode(event.target.value);
    }
  }

  return (
    <>
      <Helmet defer={false}>
        <title>Create a free account | Zirconnect</title>
        <link rel="canonical" href={Api.defaults.baseURL + path} />
      </Helmet>
      <Container text style={{marginTop:64}}>
        <Header as='h1'>Email Verification</Header>
        <p className="description">Please enter the 6-digit verification code that was sent to {email}. The code is valid for 30 minutes.</p>

        <Grid>
          <Grid.Column mobile={16} tablet={9} computer={9}>
            <Form size='big'>
              <Form.Field>
                <label style={{fontSize: 14, fontWeight: 400}}>Email Verification Code</label>
                <Input
                  type='text'
                  maxLength={6}
                  loading={loading}
                  disabled={loading}
                  value={code}
                  onChange={handleChange}
                />
              </Form.Field>
            </Form>
          </Grid.Column>
        </Grid>

        <div style={{marginTop: 24, fontSize: 14}}>
          <p>
            <button className="btn-link" onClick={() => resendEmail()}>Resend email</button>
          </p>
          <p>
            <button className="btn-link" onClick={() => setOpenHint(true)}>Didn't receive the code?</button>
          </p>
        </div>

        <Modal size='mini' open={open}>
          <Modal.Content>
            <p>{content}</p>
          </Modal.Content>
          <Modal.Actions>
            <Button color='blue' onClick={() => setOpen(false)} fluid style={{marginLeft: 0}}>
              OK
            </Button>
          </Modal.Actions>
        </Modal>

        <Modal size='small' open={openHint} onClose={() => setOpenHint(false)}>
          <Modal.Header>
            Didn't receive email code?
          </Modal.Header>
          <Modal.Content>
            <p>
              Code sent to your email. If you have not received the code after several attempts, please try:
            </p>
            <p>
              1. Check if it is in your junk/spam mail.<br />
              2. Make sure your email address is <b>{email}</b><br />
              3. The message may be delayed for a few minutes. Try again after 20 minutes.<br />
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button color='blue' onClick={() => setOpenHint(false)} fluid style={{marginLeft: 0}}>
              OK
            </Button>
          </Modal.Actions>
        </Modal>
      </Container>
    </>
  );
}

const RegisterVerification = { path, Page }

export default RegisterVerification;
