import { useEffect, useState }  from 'react';
import { Link, useHistory } from "react-router-dom";
import {
  Button,
  Container,
  Grid,
  Header,
  Icon,
  Input,
  Form,
  Checkbox,
  Modal,
} from 'semantic-ui-react';
import { AxiosResponse, AxiosError } from 'axios';
import { Helmet } from 'react-helmet';
import ReCAPTCHA from 'react-google-recaptcha';
import { Api, SessionStorageKey, SiteKey } from './service';
import Login from './Login';
import RegisterVerification from './RegisterVerification';

const path = '/register';

function Page() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordHide, setPasswordHide] = useState(true);
  const [checked, setChecked] = useState(true);
  const [errorVisible, setErrorVisible] = useState(false);
  const [emailError, setEmailError] = useState<string>();
  const [passwordError, setPasswordError] = useState<string>();
  const [checkboxError, setCheckboxError] = useState<string>();
  const [open, setOpen] = useState(false);
  const [openCaptcha, setOpenCaptcha] = useState(false);
  const [captchaToken, setCaptchaToken] = useState<string>();

  useEffect(() => {
    sessionStorage.removeItem(SessionStorageKey.register);
  });

  useEffect(() => {
    if (email !== undefined) {
      if (email.length === 0) {
        setEmailError('Please enter your email');
      } else if (!/\S+@\S+\.\S+/.test(email)) {
        setEmailError('Please enter a correct email address');
      } else {
        setEmailError(undefined);
      }
    } 

    if (password !== undefined) {
      if (password.length === 0) {
        setPasswordError('Please enter your password');
      } else if (password.length < 8) {
        setPasswordError('Password must be at least 8 characters');
      } else {
        setPasswordError(undefined);
      }
    }

    if (!checked) {
      setCheckboxError('You must agree to the terms and conditions');
    } else {
      setCheckboxError(undefined);
    }
  }, [email, password, checked]);

  const handleSubmit = () => {
    setErrorVisible(true);

    if (emailError || passwordError || checkboxError) {
      return;
    }

    setOpenCaptcha(true);
  }

  const register = (captchaToken: string) => {
    setLoading(true);
    setEmailError(undefined);
    setPasswordError(undefined);
    setCheckboxError(undefined);

    Api.post('api/account/register/request', {
      email: email,
      password: password,
      captcha_token: captchaToken,
    }).then((value: AxiosResponse) => {
      sessionStorage.setItem(SessionStorageKey.register, JSON.stringify({
        email: email,
        password: password,
        uniqueId: value.data.unique_id,
      }));

      history.push({
        pathname: RegisterVerification.path,
        state: {
          email: email,
          password: password,
          uniqueId: value.data.unique_id,
        }
      });
    })
    .catch((reason: AxiosError) => {
      if (reason.response?.status === 404) {
        if (reason.response?.data.email) {
          setEmailError('The email has already been taken.');
        }
      } else {
        setOpen(true);
      }

      setLoading(false);
    });
  }

  return (
    <>
      <Helmet defer={false}>
        <title>Create a free account | Zirconnect</title>
        <link rel="canonical" href={Api.defaults.baseURL + path} />
      </Helmet>

      <Container text style={{marginTop:64}}>
        <Header as='h1'>Create Zirconnect Account</Header>
        <p className="description">Register with your email</p>

        <Grid>
          <Grid.Column mobile={16} tablet={9} computer={9}>
            <Form size='big' onSubmit={handleSubmit}>
              <Form.Field error={errorVisible && emailError !== undefined}>
                <label style={{fontSize: 14, fontWeight: 400}}>Email</label>
                <Input
                  value={email}
                  onChange={(_, data) => setEmail(data.value)}
                  disabled={loading}
                />
                {errorVisible && <div style={{fontSize: 14, color: 'rgb(207, 48, 74)'}}>{emailError}</div>}
              </Form.Field>
              <Form.Field error={errorVisible && passwordError !== undefined}>
                <label style={{fontSize: 14, fontWeight: 400}}>Password</label>
                <Input
                  type={passwordHide ? 'password' : 'text'}
                  value={password}
                  onChange={(_, data) => setPassword(data.value)}
                  disabled={loading}
                  icon={<Icon name={passwordHide ? 'eye slash' : 'eye'} link onClick={() => setPasswordHide(!passwordHide)}/>}
                />
                {errorVisible && <div style={{fontSize: 14, color: 'rgb(207, 48, 74)'}}>{passwordError}</div>}
              </Form.Field>
              <Form.Field error={errorVisible && checkboxError !== undefined}>
                <Checkbox
                  checked={checked}
                  onClick={(_, data) => setChecked(data.checked ?? false)}
                  disabled={loading}
                  label={(
                    <label style={{fontSize: 14, fontWeight: 400}}>
                      I have read and agree to Zirconnect’s&nbsp;
                      <a href="https://www.zirconnect.com/terms" target="_blank" rel="noreferrer">Terms of Service</a> 
                    </label>
                  )}
                />
                {errorVisible && <div style={{fontSize: 14, color: 'rgb(207, 48, 74)'}}>{checkboxError}</div>}
              </Form.Field>
              <Button type='submit' size='big' color='blue' fluid disabled={loading} loading={loading}>Create Account</Button>
            </Form>

            <div style={{marginTop: 24, fontSize: 14}}>
              <p>
                Already registered? <Link to={Login.path}>Log In</Link>
              </p>
            </div>
          </Grid.Column>
        </Grid>

        <Modal size='mini' open={open}>
          <Modal.Content>
            <p>Service Unavailable</p>
          </Modal.Content>
          <Modal.Actions>
            <Button color='blue' onClick={() => setOpen(false)} fluid style={{marginLeft: 0}}>
              OK
            </Button>
          </Modal.Actions>
        </Modal>

        <Modal style={{minWidth: 345}} size='mini' open={openCaptcha} onClose={() => !captchaToken && setOpenCaptcha(false)}>
          <Modal.Header>
            Security Verification
          </Modal.Header>
          <Modal.Content>
            <div style={{width: 304, height: 78, margin: '0 auto'}}>
              <ReCAPTCHA
                sitekey={SiteKey}
                onChange={(token) => {
                  setCaptchaToken(token ?? undefined);
                  if (token) {
                    setTimeout(() => {
                      setOpenCaptcha(false);
                      register(token);
                      setCaptchaToken(undefined);
                    }, 1500);
                  }
                }}
              />
            </div>
          </Modal.Content>
        </Modal>
      </Container>
    </>
  );
}

const Register = { path, Page }

export default Register;
