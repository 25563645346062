import { useEffect, useState }  from 'react';
import { useHistory } from "react-router-dom";
import {
  Button,
  Container,
  Grid,
  Header,
  Input,
  Form,
  Modal,
} from 'semantic-ui-react';
import { AxiosResponse } from 'axios';
import { Helmet } from 'react-helmet';
import ReCAPTCHA from 'react-google-recaptcha';
import { Api, SessionStorageKey, SiteKey } from './service';
import ResetPasswordVerification from './ResetPasswordVerification';

const path = '/reset-password';

function Page() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [errorVisible, setErrorVisible] = useState(false);
  const [emailError, setEmailError] = useState<string>();
  const [open, setOpen] = useState(false);
  const [openCaptcha, setOpenCaptcha] = useState(false);
  const [captchaToken, setCaptchaToken] = useState<string>();

  useEffect(() => {
    sessionStorage.removeItem(SessionStorageKey.resetPassword);
  });

  useEffect(() => {
    if (email !== undefined) {
      if (email.length === 0) {
        setEmailError('Please enter your email');
      } else if (!/\S+@\S+\.\S+/.test(email)) {
        setEmailError('Please enter a correct email address');
      } else {
        setEmailError(undefined);
      }
    } 
  }, [email]);

  const handleSubmit = () => {
    setErrorVisible(true);

    if (emailError) {
      return;
    }

    setOpenCaptcha(true);
  }

  const resetPasswordRequest = (captchaToken: string) => {
    setLoading(true);

    Api.post('api/account/reset-password/request', {
      email: email,
      captcha_token: captchaToken,
    }).then((value: AxiosResponse) => {
      sessionStorage.setItem(SessionStorageKey.resetPassword, JSON.stringify({
        email: email,
        uniqueId: value.data.unique_id,
        lastTime: new Date(),
      }));

      history.push({
        pathname: ResetPasswordVerification.path,
        state: {
          email: email,
          uniqueId: value.data.unique_id,
        }
      });
    })
    .catch(() => {
      setOpen(true);
      setLoading(false);
    });
  }

  return (
    <>
      <Helmet defer={false}>
        <title>Reset Your Password | Zirconnect</title>
        <link rel="canonical" href={Api.defaults.baseURL + path} />
      </Helmet>

      <Container text style={{marginTop:64}}>
        <Header as='h1'>Reset Your Password</Header>
        <p className="description"></p>

        <Grid>
          <Grid.Column mobile={16} tablet={9} computer={9}>
            <Form size='big' onSubmit={handleSubmit}>
              <Form.Field error={errorVisible && emailError !== undefined}>
                <label style={{fontSize: 14, fontWeight: 400}}>Enter the account email</label>
                <Input
                  value={email}
                  onChange={(_, data) => setEmail(data.value)}  
                  disabled={loading}
                />
                {errorVisible && <div style={{fontSize: 14, color: 'rgb(207, 48, 74)'}}>{emailError}</div>}
              </Form.Field>
              <Button type='submit' size='big' color='blue' fluid loading={loading} disabled={loading}>Next</Button>
            </Form>
          </Grid.Column>
        </Grid>

        <Modal size='mini' open={open}>
          <Modal.Content>
            <p>Service Unavailable</p>
          </Modal.Content>
          <Modal.Actions>
            <Button color='blue' onClick={() => setOpen(false)} fluid style={{marginLeft: 0}}>
              OK
            </Button>
          </Modal.Actions>
        </Modal>

        <Modal style={{minWidth: 345}} size='mini' open={openCaptcha} onClose={() => !captchaToken && setOpenCaptcha(false)}>
          <Modal.Header>
            Security Verification
          </Modal.Header>
          <Modal.Content>
            <div style={{width: 304, height: 78, margin: '0 auto'}}>
              <ReCAPTCHA
                sitekey={SiteKey}
                onChange={(token) => {
                  setCaptchaToken(token ?? undefined);
                  if (token) {
                    setTimeout(() => {
                      setOpenCaptcha(false);
                      resetPasswordRequest(token);
                      setCaptchaToken(undefined);
                    }, 1500);
                  }
                }}
              />
            </div>
          </Modal.Content>
        </Modal>
      </Container>
    </>
  );
}

const ResetPassword = { path, Page }

export default ResetPassword;
