import React, { useEffect, useState }  from 'react';
import { useHistory } from "react-router-dom";
import {
  Button,
  Container,
  Grid,
  Header,
  Input,
  Form,
  Modal,
} from 'semantic-ui-react';
import { AxiosResponse, AxiosError } from 'axios';
import { Helmet } from 'react-helmet';
import { Api, SessionStorageKey } from './service';
import ResetPassword from './ResetPassword';
import ResetPasswordForm from './ResetPasswordForm';

const path = '/reset-password/2';

function Page() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [uniqueId, setUniqueId] = useState('');
  const [code, setCode] = useState('');
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState('');

  useEffect(() => {
    const text = sessionStorage.getItem(SessionStorageKey.resetPassword);

    if (text === null) {
      history.replace(ResetPassword.path);
    } else {
      const storage = JSON.parse(text);
      setEmail(storage.email);
      setUniqueId(storage.uniqueId);
    }
  }, [history]);

  useEffect(() => {
    if (code.length === 6) {
      setLoading(true);

      Api.post('api/account/reset-password/token', {
        unique_id: uniqueId,
        otp: code,
      }).then((value: AxiosResponse) => {
        sessionStorage.removeItem(SessionStorageKey.resetPassword);
        sessionStorage.setItem(SessionStorageKey.resetPasswordToken, JSON.stringify({
          token: value.data.token,
          email: email,
        }));

        history.push({
          pathname: ResetPasswordForm.path,
          state: {
            token: value.data.token,
            email: email,
          }
        });
      })
      .catch((reason: AxiosError) => {
        if (reason.response?.status === 400) {
          setContent('Invalid verification code');
        } else {
          setContent('Service Unavailable');
        }

        setOpen(true);
        setLoading(false);
        setCode('');
      });
    }
  }, [code, email, history, uniqueId]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (new RegExp('^\\d*$').test(event.target.value)) {
      setCode(event.target.value);
    }
  }

  return (
    <>
      <Helmet defer={false}>
        <title>Reset Your Password | Zirconnect</title>
        <link rel="canonical" href={Api.defaults.baseURL + path} />
      </Helmet>
      <Container text style={{marginTop:64}}>
        <Header as='h1'>Security verification</Header>
        <p className="description">To secure your account, please complete the following verification.</p>

        <Grid>
          <Grid.Column mobile={16} tablet={9} computer={9}>
            <Form size='big'>
              <Form.Field>
                <label style={{fontSize: 14, fontWeight: 400}}>Email Verification Code</label>
                <Input
                  type='text'
                  maxLength={6}
                  loading={loading}
                  disabled={loading}
                  value={code}
                  onChange={handleChange}
                />
              </Form.Field>
              <span style={{fontSize: 14, fontWeight: 400, color: '#707A8A'}}>
                Enter the 6 digit code received by {email.replace(/(\w{3})[\w.-]+@([\w.]+\w)/, '$1***@$2')}.
              </span>
            </Form>
          </Grid.Column>
        </Grid>

        <Modal size='mini' open={open}>
          <Modal.Content>
            <p>{content}</p>
          </Modal.Content>
          <Modal.Actions>
            <Button color='blue' onClick={() => setOpen(false)} fluid style={{marginLeft: 0}}>
              OK
            </Button>
          </Modal.Actions>
        </Modal>
      </Container>
    </>
  );
}

const ResetPasswordVerification = { path, Page }

export default ResetPasswordVerification;
